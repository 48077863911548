<template>

<div class="page-wrapper">
    <div class="text-right">
        <b-button
            v-if="!isNew"
            @click="onDelete()"
            variant="danger">
            Delete Quote
        </b-button>
    </div>
    <b-card no-body footer-tag="footer">
        <b-tabs card>
            <b-tab title="Quote">
                <b-form>
                    <span v-if="error" class="error">{{ error }}</span>
                        <inline-form-row label="Client" _type="b-form-select" :options="clients" :disabled="!this.isNew" v-model="quote.client" :errors="errors['client']" @change="changeClient()"/>
                        <inline-form-row label="Opened" _type="b-form-datepicker" v-model="quote.opened" :errors="errors['opened']"/>
                        <BMSCurrencySelector label="Currency" v-model="quote.currency" :errors="errors['currency']" />
                        <inline-form-row label="Description" _type="b-form-textarea" v-model="quote.description" :errors="errors['description']" />
                </b-form>
            </b-tab>
            <b-tab title="Items" :disabled="isNew">
                <div class="text-right" v-show="isEditable">
                    <b-button @click="addItem()" class="m-1">Add item</b-button>
                </div>
                <b-table
                    :items="quote.items"
                    :fields="fields">
                    <template v-for="(field, index) in editableFields" v-slot:[`cell(${field.key})`]="row">
                        <b-input v-model="row.item[field.key]" :key="index + '-input'" :disabled="!isEditable"/>
                        <span
                        v-if="errors && errors['items'] && errors['items'][row.index] && errors['items'][row.index][field.key]"
                        class="error" :key="index + '-span'">
                        {{ errors['items'][row.index][field.key] }}
                        </span>
                    </template>
                    <template #cell(total)="row">
                        ${{ getItemTotal(row.item).toFixed(2) }}
                    </template>
                    <template #cell(actions)="row">
                        <b-button @click="onDeleteItem(row.index)" variant="danger" v-show="isEditable">
                            <b-icon icon="trash" />
                        </b-button>
                    </template>
                </b-table>
            </b-tab>
        </b-tabs>
        <template #footer>
            <b-row>
                <b-col>
                    <span v-show="isEditable">
                        Tax Rule
                        <b-form-select v-model="applied_tax" :options="tax_options"  />
                    </span>
                </b-col>
                <b-col cols="2">Sub-Total<br>
                    <span v-for="(tax, i) in applied_tax.individual_taxes" :key="i">
                        Estimated {{tax.name}} {{tax.percentage}}%<br>
                    </span>
                    <strong>Total ({{quote.currency}})</strong>
                </b-col>
                <b-col cols="2" class="text-left">
                    <span>${{ subtotal.toFixed(2) }}<br></span>
                    <span v-for="(tax, i) in applied_tax.individual_taxes" :key="i">
                        ${{ get_tax_amount(tax).toFixed(2) }}<br>
                    </span>
                    <strong class="text-primary">${{grandTotal.toFixed(2)}}</strong>
                </b-col>
                <b-col></b-col>
            </b-row>
        </template>
    </b-card>

    <div class="text-right">
        <b-button v-b-modal.create-project-modal class="btn btn-primary" v-if="quote.status == 'Generated'">
            Convert to Invoice 
        </b-button>

        <button type="submit" class="btn btn-primary" v-if="isEditable & !isNew" @click.prevent="onGenerate()">
            Generate Quote
        </button>

        <button type="submit" class="btn btn-primary" v-if="!isEditable & quote.status != 'Deleted'" @click.prevent="onGenerate()">
            Download PDF
        </button>

        <button type="submit" class="btn btn-success" v-if="isEditable" @click.prevent="onSave()">
            Save quote
        </button>

         <b-modal id="create-project-modal" title="Create Project" ok-variant="success" ok-title="Save" cancel-variant="danger" @ok.prevent="onSaveProject()">
            <b-form>
                <span v-if="error" class="error">{{ error }}</span>
                <inline-form-row label="Client" _type="b-form-select" :options="clients" :disabled="!this.isNew" v-model="quote.client" :errors="errors['client']" class="mb-3"/>
                <inline-form-row label="Name" type="text" v-model="project.name" :errors="errors['name']" helpText="Give the project a useful name - make sure to include (Phase number, year/month, technologies)" class="mb-3"/>
                <inline-form-row label="Description" _type="b-form-textarea"  v-model="project.description" :errors="errors['description']" class="mb-3" :rows="5"/>
                <inline-form-row label="Purchase Order" type="text" v-model="project.purchase_order" :errors="errors['email']"  helpText="Purchase Order giving by the client or partner"/>
                <inline-form-row label="Default Invoice" _type="b-form-textarea" :disabled=true v-model="defaultInvoice"/>
            </b-form>
         </b-modal>
    </div>

  </div>
</template>

<script>

import InlineFormRow from '@/components/InlineFormRow'
import BMSCurrencySelector from '@/components/BMSCurrencySelector'
import crudMixin from '@/api/crud'
import confirmationModal from '@/api/confirmation'
import { getSetting } from '@/api/settings.api'
import { listApplicableTaxes, getApplicableTax } from '@/api/taxes.api'


import { getAllClients } from '@/api/clients.api'
import { getQuote, createQuote, convertToInvoice, updateQuote, generateQuote, deleteQuote } from '@/api/quotes.api'
import {createProject} from '@/api/projects.api'

export default {
    mixins: [crudMixin, confirmationModal],
    data() {
        return {
            id: this.$route.params.id,
            quote: {items: [], taxes: []},
            clients: [],
            project: {
                name: this.getDefaultProjectName(),
            },
            defaultInvoice: null,
            default_applicable_tax: null,
            applied_tax: this.none_tax,
            applicable_taxes: [],
            fields: [{
                key: 'description',
                label: 'Description',
                editable: true,
            }, {
                key: 'quantity',
                label: 'Quantity',
                editable: true,
            }, {
                key: 'cost',
                label: 'Price',
                editable: true,
            }, {
                label: 'Total',
                key: 'total'
            }, {
                label: 'Action',
                key: 'actions'
            }]
        }
    },
    components: {
        InlineFormRow,
        BMSCurrencySelector
    },
    beforeMount() {
        this.applied_tax = this.none_tax
    },
    computed: {
        areAllTimesheetsSelected: function() {
            var toReturn = true
            this.timesheets.forEach(timesheet => {
                if(!timesheet.selected) {
                    toReturn = false
                    return
                }
            })
            return toReturn
        },
        tax_options: function() {
            return this.applicable_taxes.map((tax) => {
                return {
                    value: tax,
                    text: `${tax.name}`
                }
            })
        },
        none_tax: function() {
            return {
                "name": "None",
                "individual_taxes": []
            }
        },
        isNew: function() {
            return this.id != parseInt(this.id)
        },
        isEditable: function() {
            return this.quote.status == 'OPEN' || this.isNew
        },
        grandTotal: function() {
            let grandtotal = this.subtotal
            this.applied_tax.individual_taxes.forEach(tax => grandtotal += this.get_tax_amount(tax))

            return grandtotal
        },
        subtotal: function() {
            var subtotal = 0
            this.quote.items.forEach(item => {
                var cost = item.quantity*item.cost
                if(!isNaN(cost)) {
                    subtotal += cost
                }
            })
            return subtotal
        },
        editableFields: function() {
            return this.fields.filter(field => field.editable)
        }
    },
    mounted() {
        this.getClients();
        this.getApplicableTaxes()
        this.getDefaultApplicableTax()
        this.getDefaultInvoice()
        if (!this.isNew) {
            this.getQuote();
        }
    },
    methods: {
        get_tax_amount(tax) {
            let toreturn = 0
            if (Object.hasOwn(tax, 'value')) {
                toreturn = parseFloat(tax.value)
            } else {
                toreturn = tax.percentage * this.subtotal / 100
            }
            return toreturn
        },
        getDefaultApplicableTax() {
            this.call(
                getSetting("default_applicable_tax"),
                (res) => {
                    let tax_number = res.data.setting.value
                    this.call(
                        getApplicableTax(tax_number),
                        (tax_res) => {
                            this.default_applicable_tax = tax_res.data.applicable_tax
                            if(this.applied_tax === this.none_tax){
                                this.applied_tax = this.default_applicable_tax
                            }
                        }
                    )
                }
            )
        },
        getItemTotal: function(item) {
            var total = item.quantity*item.cost
            if(isNaN(total)) {
                total = 0
            }
            return total
        },
        addItem: function() {
            this.quote.items.push({})
        },
        getClients() {
            this.call(
                getAllClients(),
                (res) => {
                    // Empty array, VueJS style
                    this.clients.splice(0, this.clients.length)
                    res.data.clients.forEach(client => {
                        this.clients.push({'value': client, 'text': client.company});
                    })
                    this.clients.sort(function(a, b){return a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1});
                }
            )
        },
        getApplicableTaxes() {
            this.applicable_taxes.splice(0)
            this.call(
                listApplicableTaxes(),
                (res) => {
                    this.applicable_taxes.push(...res.data.applicable_taxes)
                }
            )
        },
        getQuote() {
            this.call(
                getQuote(this.id),
                (res) => {
                    this.quote = res.data.quote;
                    this.quote.taxes = this.decodeJSON(this.quote.taxes, []);
                    if(this.quote.taxes == null) {
                        this.quote.taxes = []
                    }
                    if (Object.hasOwn(this.quote.taxes, 'id')) {
                        this.applied_tax = this.applicable_taxes.filter(tax => tax.id === this.quote.taxes.id)[0]
                    } else {
                        this.applied_tax = {
                            "individual_taxes": this.quote.taxes
                        }
                    }
                }
            )
        },
        onDelete() {
            // confirmModal(message, okTitle, cancelTitle)
            this.confirmModal().then((result) => {
                if (result){
                    this.deleteObject(
                    deleteQuote(this.id),
                    () => {
                        this.$router.push('/quotes');
                    }
                    )
                }
            })
        },
        formatDate(date) {
          // Split date by month, day and year
          var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

          // Padding with "0"
          if (month.length < 2)
            month = '0' + month;
          if (day.length < 2)
              day = '0' + day;

          // Return date yyyy-mm-dd
          return [year, month, day].join('-');
        },
        decodeJSON(val, empty) {
          try {
            return JSON.parse(val);
          } catch (e) {
            return empty;
          }
        },
        onDeleteItem(index) {
            this.quote.items.splice(index, 1);
        },
        onGenerate() {
            this.call(
                generateQuote(this.id),
                (res) => {
                    this.$awn.success('Quote generated successfully!');
                    window.open(res.data.url, "_blank");

                    // Refresh data
                    this.getQuote();
                }
            )
        },
        changeClient() {
            if (this.quote.client.accounts_payable == null){
                this.defaultCurrency = null
            } else{
                this.defaultCurrency =  this.quote.client.accounts_payable.currency
                this.quote.currency = this.defaultCurrency
            }

            if(this.quote.client.applicable_tax) {
                this.applied_tax = this.quote.client.applicable_tax
            } else{
                this.applied_tax = this.default_applicable_tax
            }
        },
        onSave(showBanner=true) {
            this.quote.taxes = JSON.stringify(this.applied_tax)

            if (this.isNew) {
                var apiCall = createQuote(this.quote)
                var callback = (res) => {
                    let findId = res.data.location.split('/');
                    this.id = findId[findId.length-1];
                    this.$router.push(`/quotes/${this.id}`);
                    this.getQuote()
                }
            } else {
                apiCall = updateQuote(this.id, this.quote)
                callback = () => {}
            }
            this.saveObject(
                apiCall,
                callback,
                showBanner
            )
        },
        getDefaultProjectName(){
            return `Project_${this.formatDate(new Date())}`;
        },
        onSaveProject(showBanner=false){
            var apiCall = createProject({...this.project, client: this.quote.client})
            var callback = (response) => {
                var findId = response.data.location.split('/');
                var projectId = findId[findId.length-1];
                this.convertToInvoice(projectId);
            }
            this.saveObject(
                apiCall,
                callback,
                showBanner
            )
        },
        getDefaultInvoice() {
            return this.defaultInvoice = `An invoice will be generated from quote: ${this.id}`;
        },
        convertToInvoice(projectId, showBanner=false) {
           var apiCall = convertToInvoice(this.id, projectId)
           var callback = (res) => {
                let findId = res.data.location.split('/');
                let invoiceId = findId[findId.length-1];
                this.$router.push(`/invoices/${invoiceId}`);
                this.$awn.success('Quote converted successfully!');
           }
           this.saveObject(
                apiCall,
                callback,
                showBanner
            )
        }
    }
}
</script>

